import Vue from 'vue'
import 'normalize.css/normalize.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// 导入全局样式
import './assets/css/global.css'
// 导入字体图标
import './assets/css/font.css'
// 多语言
import VueI18n from 'vue-i18n'
import Language from './lang/lang.json'

// 导入并配置axios
import axios from 'axios'
import Directives from './assets/js/Directives'
// 重复按钮
Vue.use(Directives)// 重复按钮
// ws
Vue.prototype.wsurl = 'wss://api.vbitex.vip'
//Vue.prototype.wsurl = 'wss://wpapi.fxcc.xyz'
// 配置请求跟路径
axios.defaults.baseURL = ' https://api.vbitex.vip'
//axios.defaults.baseURL = '/api'
// 请求拦截器
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})
// 响应拦截器
axios.interceptors.response.use(response => {
  if (response.data.code === 401) {
    window.sessionStorage.setItem('token', '1')
    router.push('/login')
    return false
  } else {
    return response
  }
})
// 正式地址 网站地址配置
Vue.prototype.weburl = 'https://api.vbitex.vip'
//测试地址
//Vue.prototype.weburl = 'https://wpapi.fxcc.xyz'
//Vue.prototype.weburl = '/api'
// 全局时间过滤器
Vue.filter('dateformat', function(time) {
  const dt = new Date(parseInt(time) * 1000)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
// 全局函数
Vue.prototype.getlang = msg => {
  if (localStorage.getItem('language') === 'en') {
    return Language[msg]
  } else {
    return msg
  }
}
// 多语言
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'zh',
  messages: {
    zh: require('./lang/zh'), // 中文语言包
    en: require('./lang/en') // 英文语言包
  }
})
Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
