<template>
  <div :class="classObj" class="app-wrapper">
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />
    <!-- 侧边栏 -->
    <div class="sidebar-container">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu
          :default-active="activeMenu"
          background-color="#282A32"
          text-color="#909399"
          active-text-color="#409EFF"
          :collapse="isCollapse"
          :collapse-transition="false"
          mode="vertical"
          :router="true"
          :unique-opened="true"
        >
          <el-menu-item index="/home">
            <i class="iconfont icon-shouye"></i>
            <template slot="title">{{ $t('common.home') }}</template>
          </el-menu-item>
          <el-submenu
            :index="item.id"
            v-for="(item, index) in menulist"
            :key="item.id"
          >
            <template slot="title">
              <i :class="icon[index]"></i>
              <span v-if="item.id === '85'"
                >{{ getlang(item.title) }}
                <span v-if="hasorder" class="isdot"></span>
              </span>
              <span v-else>{{ getlang(item.title) }} </span>
            </template>
            <el-menu-item
              :index="'/' + citem.controller + '/' + citem.action"
              v-for="citem in item.sub"
              :key="citem.id"
              >{{ getlang(citem.title) }}</el-menu-item
            >
          </el-submenu>
        </el-menu>
      </el-scrollbar>
    </div>
    <!--主体区域-->
    <div class="main-container">
      <!-- 右侧头部 -->
      <div class="navbar">
        <div
          style="padding: 0 15px;"
          @click="toggleClick"
          class="hamburger-container"
        >
          <svg
            :class="{ 'is-active': !isCollapse }"
            class="hamburger"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
          >
            <path
              d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"
            />
          </svg>
        </div>
        <el-breadcrumb class="app-breadcrumb" separator="/">
          <transition-group name="breadcrumb" mode="out-in">
            <el-breadcrumb-item
              v-for="(item, index) in levelList"
              :key="item.path"
            >
              <span
                v-if="
                  item.redirect === 'noRedirect' ||
                    index == levelList.length - 1
                "
                class="no-redirect"
                >{{ getlang(item.meta.title) }}</span
              >
              <a v-else @click.prevent="handleLink(item)">{{
                getlang(item.meta.title)
              }}</a>
            </el-breadcrumb-item>
          </transition-group>
        </el-breadcrumb>
        <div class="right-menu">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              {{ lang }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="Language('zh')"
                >中文简体</el-dropdown-item
              >
              <el-dropdown-item @click.native="Language('en')"
                >English</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link" style="font-weight:600">
              {{ getlang(user.group) }}：{{ user.username }}
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="editpass">{{
                $t('common.xgmm')
              }}</el-dropdown-item>
              <el-dropdown-item @click.native="logout">{{
                $t('common.tcdl')
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!-- 右侧内容主体 -->
      <el-main>
        <transition name="fade-transform" mode="out-in">
          <router-view :key="key" />
        </transition>
      </el-main>
      <!-- 修改密码 -->
      <el-dialog
        :title="$t('xgmm.grxx')"
        :visible.sync="editpassshow"
        width="50%"
        :before-close="handleClose"
      >
        <el-form
          ref="passform"
          :rules="rules"
          :model="passform"
          label-width="160px"
        >
          <el-form-item :label="$t('xgmm.jmm')" prop="oldpass">
            <el-input
              type="password"
              v-model="passform.oldpass"
              :placeholder="$t('xgmm.jmmts')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('xgmm.xmm')" prop="newpass">
            <el-input
              type="password"
              v-model="passform.newpass"
              :placeholder="$t('xgmm.xmmts')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('xgmm.zcsrmm')" prop="newpass2">
            <el-input
              type="password"
              v-model="passform.newpass2"
              :placeholder="$t('xgmm.zcmmts')"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
          <el-button type="primary" @click="passsubmit">{{
            $t('tijiao')
          }}</el-button>
        </span>
      </el-dialog>
    </div>
     <audio controls ref="csss"  style="display: none;" >
      <source :src="require('../assets/mp3/chongbi.mp3')" />
    </audio>
    <audio controls ref="tixian"  style="display: none;">
      <source :src="require('../assets/mp3/tibi.mp3')" />
    </audio>
    
  </div>
</template>

<script>
import ResizeMixin from '@/plugins/mixin'

export default {
  data() {
    return {
      levelList: null,
      menulist: [],
      user: [],
      icon: [
        'iconfont icon-chanpinguanli',
        'iconfont icon-dingdan',
        'iconfont icon-huaban25',
        'iconfont icon-zuocaidanicon-07',
        'iconfont icon-user',
        'iconfont icon-left-jcgl',
        'iconfont icon-market-info',
        'iconfont icon-cheliangguanliiconbeifen',
        'iconfont icon-canshushezhi',
        'iconfont icon-shezhi'
      ],
      hasorder: false,
      isAutio:false,
      ordercount: 0,
      timer: null,
      lang: localStorage.getItem('language') === 'en' ? 'English' : '中文简体',
      editpassshow: false,
      passform: {
        oldpass: '',
        newpass: '',
        newpass2: ''
      },
      rules: {
        oldpass: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newpass: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        newpass2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  mixins: [ResizeMixin],
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
    this.getbaseinfo()
    this.timer = setInterval(() => {
      this.getorder()
    }, 60000)
  },
  methods: {
    // 请求侧边栏数据
    async getbaseinfo() {
      const { data } = await this.$http.get('/admin/user/baseinfo')
      this.menulist = data.data.menulist
      this.user = data.data.user
      this.$store.dispatch('setMenulist', data.data.menulist)
    },
    // 设置面包屑导航名称
    getBreadcrumb() {
      const matched = this.$route.matched.filter(
        item => item.meta && item.meta.title
      )
      this.levelList = matched.filter(
        item => item.meta && item.meta.title && item.meta.breadcrumb !== false
      )
    },
    // 关闭/开启侧边栏
    toggleClick() {
      this.$store.dispatch('toggleSideBar')
    },
    // 点击移动端遮罩层关闭侧边栏
    handleClickOutside() {
      this.$store.dispatch('closeSideBar', { withoutAnimation: false })
    },
    // 退出登陆
    async logout() {
      const { data } = await this.$http.post('/admin/login/logout')
      if (data) {
        if (data.code === 200) {
          this.$message.success(data.msg)
        } else {
          this.$message.error(data.msg)
        }
      }
      window.sessionStorage.removeItem('token')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    // 中英文切换
    Language(lang) {
      if (lang === 'en') {
        this.lang = 'English'
      } else {
        this.lang = '中文简体'
      }
      this.$i18n.locale = lang
      localStorage.setItem('language', lang)
    },
    // 修改密码
    editpass() {
      this.editpassshow = true
      // this.$message.success('修改密码')
    },
    // 订单提醒
    async getorder() {
      const { data } = await this.$http.get('/admin/orders/getcount')
    
      if (data) {
        if (data.code === 200) {
          
          if(data.data.regnum>0)
          {
            
            this.$refs.csss.play();
          }
          if(data.data.witnum>0)
          {
            this.$refs.tixian.play();
          }
          //this.isAutio = false;
          // if (this.ordercount) {
          //   this.hasorder = data.data > this.ordercount
          //   if (this.hasorder) {
          //     this.$refs.notify.play()
          //   }
          // } else {
          //   this.ordercount = data.data
          // }
        }
      }
    },
    // 修改密码
    passsubmit() {
      if (this.passform.newpass2 !== this.passform.newpass) {
        this.$message.error(this.$t('xgmm.lcmmbyz'))
        return false
      }
      this.$refs.passform.validate(async valid => {
        if (!valid) return false
        this.passform.id = this.user.id
        const { data } = await this.$http.post(
          '/admin/user/editpass',
          this.passform
        )
        if (data.code !== 200) {
          return this.$message.error(this.getlang(data.msg))
        } else {
          this.$message.success(this.$t('chenggong'))
          this.editpassshow = false
        }
      })
    },
    handleClose() {
      this.$refs.passform.resetFields()
      this.editpassshow = false
    }
  },
  computed: {
    device() {
      return this.$store.state.device
    },
    sidebar() {
      return this.$store.state.sidebar
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    key() {
      return this.$route.path
    },
    activeMenu() {
      const route = this.$route
      // console.log(route)
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/css/sidebar.less';
.app-wrapper {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  position: relative;
  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - 210px);
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    .hamburger {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
    }

    .hamburger.is-active {
      transform: rotate(180deg);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .app-breadcrumb.el-breadcrumb {
    display: inline-block;
    font-size: 14px;
    line-height: 50px;
    margin-left: 8px;

    .no-redirect {
      color: #97a8be;
      cursor: text;
    }
  }
  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    cursor: pointer;
    margin-right: 13px;
    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
    .el-dropdown {
      color: #2d2d2d;
      margin: 0 10px;
    }
  }
}
.isdot {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 3px;
  background: #f00;
}
</style>
